export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Online Courses",
    desc: "Learn online and earn valuable credentials from top universities like Yale, Michigan, Stanford, and leading companies like Google and IBM.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Earn A Certificates",
    desc: "Access 2000 free online courses from 140 leading institutions worldwide. Gain new skills and earn a certificate of completion. Join today.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Learn with Expert",
    desc: "Courses is the best online learning site website that offers free and open access to introductory courses that are taught by expert teachers",
  },
]

export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
]

export const courseInstructorCard = [
  {
    id: 1,
    cover: "../images/course/course1.jpg",
    instImage:"../images/creator/i1.jpg",
    courseLink:"https://www.youtube.com/embed/0IAPZzGSbME",
    coursesName: "Mastering Data Structures",
    instrName: "Dr.Abdul bari",
    workingAt:"Professor at IIT Madras",
    desCap:"Start Learning Data Strucutures",
    desc: "Learn, Analyse and Implement Data Structure using C and C++. Learn Recursion and Sorting.",
    lastUpdated: "updated 27/10/2022",
    rating:"4.6",
    totalUsers: "(6927)",
    discountPrice: "₹ 2, 999",
  },
  {
    id: 2,
    cover: "../images/course/course2.png",
    instImage:"../images/creator/i2.jpg",
    courseLink:"https://www.youtube.com/embed/ARR5tirPdUQ",
    workingAt:"Professor at IIIT Sricity",
    desCap:"Start Learning C language",
    desc: "C Programming will increase career options. Become a better dev in other languages by learning C.",
    coursesName: "Master the C Language",
    instrName: "Dr. Annushree Bablani",
    lastUpdated: "updated 01/10/2022",
    rating:"4.9",
    totalUsers: "(13854)",
    discountPrice: "₹ 3, 799",
  },
  {
    id: 3,
    cover: "../images/course/course3.png",
    instImage:"../images/creator/i3.jpg",
    courseLink:"https://www.youtube.com/embed/EjavYOFoJJ0",
    desCap:"Start Learning C++ language",
    workingAt:"Asst. Professor at IIIT Hyderabad",
    desc: "Learning C++ Language will increase career options. OOPS is a important Concept in C++.",
    coursesName: "C++ for Beginner's",
    instrName: "Dr. Priyambada",
    lastUpdated: "updated 31/10/2022",
    rating:"4.7",
    totalUsers: "(1942)",
    discountPrice: "₹ 2, 199",
  },
  {
    id: 4,
    cover: "../images/course/course4.png",
    instImage:"../images/creator/i4.jpg",
    courseLink:"https://www.youtube.com/embed/VHbSopMyc4M",
    workingAt:"Professor at IIT Kanpur",
    desc: "Learn Java In This Course And Become a Computer Programmer. Obtain valuable Core Java Skills And Java Certification.",
    desCap:"Start Learning Java",
    coursesName: "OOPS From Scratch",
    instrName: "Dr. Balasubramanian",
    lastUpdated: "updated 24/10/2022",
    rating:"4.1",
    totalUsers: "(4198)",
    discountPrice: "₹ 4, 599",
  },
  {
    id: 5,
    cover: "../images/course/course5.png",
    instImage:"../images/creator/i5.jpg",
    courseLink:"https://www.youtube.com/embed/I2wURDqiXdM",
    workingAt:"Professor at IIT Kharagpur",
    desc: "Master Python by building 100 projects in 100 days. Learn data science, automation, build websites, games and apps!.",
    desCap:"Start Learning Python",
    coursesName: "Python Bootcamp 2022",
    instrName: "Dr. Bheemappa Halavar",
    lastUpdated: "updated 05/09/2022",
    rating:"4.3",
    totalUsers: "(3914)",
    discountPrice: "₹ 1, 459",
  },
  {
    id: 6,
    cover: "../images/course/course6.png",
    instImage:"../images/creator/i6.jpg",
    courseLink:"https://www.youtube.com/embed/VSEnzzjAm0c",
    workingAt:"Professor at IIT Delhi",
    desc: "C Programming will increase career options. Become a better dev in other languages by learning C.",
    desCap:"Start Learning C language",
    coursesName: "C Programming With Dsa",
    instrName: "Dr. Himangshu Sarma",
    lastUpdated: "updated 15/09/2022",
    rating:"3.9",
    totalUsers: "(9854)",
    discountPrice: "₹ 3, 499",
  },
  {
    id: 7,
    cover: "../images/course/course7.jpg",
    instImage:"../images/creator/i7.jpg",
    courseLink:"https://www.youtube.com/embed/-T3kCiqMoFk",
    workingAt:"Professor at Manipal University",
    desc: "One place for Begginers to learn from html to React, Backend...",
    desCap:"Start Learning web devlopment with NodeJs",
    coursesName: "Ultimate Web Development",
    instrName: "Dr. Brad Hussey",
    lastUpdated: "updated 21/08/2022",
    rating:"4.4",
    totalUsers: "(4132)",
    discountPrice: "₹ 2, 199",
  },
  {
    id: 8,
    cover: "../images/course/course8.jpeg",
    instImage:"../images/creator/i8.jpg",
    courseLink:"https://www.youtube.com/embed/MM6TjxrmvoU",
    workingAt:"Professor at IIM Banglore",
    desc: "Best React Bootcamp at one place, including mock-backend, many intresting projects",
    desCap:"Start Learning web development with React",
    coursesName: "React Bootcamp",
    instrName: "Dr. Angela Yu",
    lastUpdated: "updated 28/09/2022",
    rating:"4.7",
    totalUsers: "(7345)",
    discountPrice: "₹ 1, 399",
  },
  {
    id: 9,
    cover: "../images/course/course9.png",
    instImage:"../images/creator/i9.jpg",
    courseLink:"https://www.youtube.com/embed/VfGW0Qiy2I0",
    workingAt:"Asst. Professor at IIT Bombay",
    desc: "COMPLETELY REDONE - The only course you need to learn web development - HTML, CSS, JS, Node, and More!",
    desCap:"Start Learning web devlopment",
    coursesName: "Full Stack Development",
    instrName: "Dr. Amit Praseed",
    lastUpdated: "updated 17/10/2022",
    rating:"4.2",
    totalUsers: "(15397)",
    discountPrice: "₹ 3, 299",
  },
  {
    id: 10,
    cover: "../images/course/course10.png",
    instImage:"../images/creator/i10.jpg",
    courseLink:"https://www.youtube.com/embed/7660DZ_HJqM",
    workingAt:"Professor at IIT Hyderabad",
    desc: "A Complete Guide to the Flutter SDK & Flutter Framework for building native iOS and Android apps",
    desCap:"Start Learning flutter development",
    coursesName: "Andriod Bootcamp",
    instrName: "Dr. Subu Kundaswamy",
    lastUpdated: "updated 26/10/2022",
    rating:"2.6",
    totalUsers: "(36784)",
    discountPrice: "₹ 2, 799",
  },
  {
    id: 11,
    cover: "../images/course/course11.png",
    instImage:"../images/creator/i11.jpg",
    courseLink:"https://www.youtube.com/embed/x0uinJvhNxI",
    workingAt:"Professor at Cambridge",
    desc: "A Complete Guide to the Flutter with help of proper analysis, hands'on sessions, 50 day projects.",
    desCap:"Start Learning flutter development",
    coursesName: "Flutter Development",
    instrName: "Dr. Neha Agarwal",
    lastUpdated: "updated 15/09/2022",
    rating:"4.7",
    totalUsers: "(27456)",
    discountPrice: "₹ 1, 359",
  },
  {
    id: 12,
    cover: "../images/course/course12.jpg",
    instImage:"../images/creator/i12.jpg",
    courseLink:"https://www.youtube.com/embed/I9ceqw5Ny-4",
    workingAt:"Professor at MIT",
    desc: "Begginer Course for who are willing to learn android development.",
    desCap:"Start Learning Android development",
    coursesName: "Andriod Development",
    instrName: "Dr. Angela Yu",
    lastUpdated: "updated 09/09/2022",
    rating:"3.3",
    totalUsers: "(6587)",
    discountPrice: "₹ 4, 499",
  },
  // {
  //   id: 13,
  //   cover: "../images/course/course13.png",
  //   instImage:"../images/creator/i13.jpg",
  //   courseLink:"https://www.youtube.com/embed/5F_JUvPq410",
  //   workingAt:"Professor at IIT Delhi",
  //   desc: "A Complete Guide to the Flutter SDK & Flutter Framework for building native iOS and Android apps",
  //   desCap:"Start Learning flutter development",
  //   coursesName: "Flutter Development",
  //   instrName: "Dr. Subu Kundaswamy",
  //   lastUpdated: "updated 26/10/2022",
  //   rating:"2.6",
  //   totalUsers: "(36784)",
  //   discountPrice: "₹ 2, 799",
  // },
  // {
  //   id: 14,
  //   cover: "../images/course/course14.png",
  //   instImage:"../images/creator/i14.jpg",
  //   courseLink:"https://www.youtube.com/embed/5F_JUvPq410",
  //   workingAt:"Professor at IIT Delhi",
  //   desc: "A Complete Guide to the Flutter SDK & Flutter Framework for building native iOS and Android apps",
  //   desCap:"Start Learning flutter development",
  //   coursesName: "Flutter Development",
  //   instrName: "Dr. Subu Kundaswamy",
  //   lastUpdated: "updated 26/10/2022",
  //   rating:"2.6",
  //   totalUsers: "(36784)",
  //   discountPrice: "₹ 2, 799",
  // },



  
]

export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
]
export const team = [
  {
    cover: "./images/team/t1.png",
    name: "Dr. Bhemmappa Halawar",
    work: "Instructor and Ast.Faculty at IIITS",
    linkedin: "https://www.linkedin.com/in/bheemappa-halavar-51070024/",
  },
  {
    cover: "./images/team/t2.jpeg",
    name: "Himangshu Sarma",
    work: "Instructor and Ast.Faculty at IIITS",
    linkedin: "https://www.linkedin.com/in/himangshu-sarma-16a35177/",
  },
  {
    cover: "./images/team/t3.jpeg",
    name: "Dr. Subu Kundaswamy",
    work: "Lead Instructor and Asoc.Faculty at IIITS",
    linkedin: "https://www.linkedin.com/in/subu-kandaswamy/",
  },
  {
    cover: "./images/team/t4.png",
    name: "Dr. Rakesh Sunodiya",
    work: "Instructor and Ast.Faculty at IIITS",
    linkedin: "https://www.linkedin.com/in/rakesh-sanodiya-a2a41916a/",
  },
  {
    cover: "./images/team/t5.jpeg",
    name: "Dr. Nikhil Tripathi",
    work: "Instructor and Ast.Faculty at IIITS",
    linkedin: "https://www.linkedin.com/in/nikhil-tripathi/",
  },
  {
    cover: "./images/team/t6.jpeg",
    name: "Dr. Aishwarya",
    work: "Lead Instructor of DSA",
    linkedin: "https://www.linkedin.com/in/palle-pranay-reddy-199464201/",
  },
  {
    cover: "./images/team/t7.jpeg",
    name: "Dr. Akshitha Reddy",
    work: "Senior Developer at MasterDsa",
    linkedin: "https://www.linkedin.com/in/palle-pranay-reddy-199464201/",
  },
  {
    cover: "./images/team/m1.jpeg",
    name: "Palle Pranay Reddy",
    work: "Team Member",
    linkedin: "https://www.linkedin.com/in/palle-pranay-reddy-199464201/",
  },
  {
    cover: "./images/team/m2.jpeg",
    name: "Shata Rupender",
    work: "Team Member",
    linkedin: "https://www.linkedin.com/in/palle-pranay-reddy-199464201/",
  },
  {
    cover: "./images/team/m3.jpg",
    name: "Abhishek Rayudu",
    work: "Team Member",
    linkedin: "https://www.linkedin.com/in/palle-pranay-reddy-199464201/",
  },
  {
    cover: "./images/team/m4.jpeg",
    name: "Sai Ritvik",
    work: "Team Member",
    linkedin: "https://www.linkedin.com/in/palle-pranay-reddy-199464201/",
  },
  {
    cover: "./images/team/m5.jpg",
    name: "Prashant Kumar",
    work: "Team Member",
    linkedin: "https://www.linkedin.com/in/palle-pranay-reddy-199464201/",
  },
]
export const price = [
  {
    name: "BEGINNER PLAN",
    price: "5, 999",
    desc: "Limited Access to all Courses, Quizes and Blogs in MasterDsa and Basic Access to Contents.",
  },
  {
    name: "PREMIUM PLAN",
    price: "8, 999",
    desc: "Unlimited Access to all Courses, Quizes and Blogs in MasterDsa and Leetcode Premium Access",
  },
  {
    name: "VIP PLAN",
    price: "13, 999",
    desc: "Unlimited Access to all Contents in MasterDsa and Leetcode Premium Access, Limited Live Classes",
  },
  {
    name: "EXECUTIVE PLAN",
    price: "16, 999",
    desc: "Unlimited Access to all Contents in MasterDsa and Leetcode Premium Access, Unlimited Live Classes",
  },
]
export const faq = [
  {
    title: "How to get access to course on MasterDsa? ",
    desc: "Add the Courses into the cart and proceed further, Checkout the cart, do the payment.",
  },
  {
    title: "What is the advantage with Premium Vip plan's? ",
    desc: "You will be having unlimite access to all content in MasterDsa and also Leetcode.",
  },
  {
    title: "How master Dsa Helps in Placements? ",
    desc: "MasterDsa helpful in getting placed better by providing specialized courses on Data Structures.",
  },
  {
    title: "What is the Solution for improving coding skills? ",
    desc: "Improve coding skills by focusing on Data Structures and problem solving.",
  },
  {
    title: "How to get Access for Live Classes? ",
    desc: "You can get Access to Live Classes with Executive Plan",
  },
  {
    title: "What are the important data structures to learn",
    desc: "Every Data Structure is important, start with Arrays Linkedlists and Learn Shortest Distance Algorithms, no limit, start doing more problems on leetcode.",
  },
]
export const blog = [
  {
    id: 1,
    type: "Pranay SDE",
    date: "OCT. 31, 2022",
    com: "71 COMMENTS ",
    title: "How to Crack Amazon Interviews",
    desc: "There is no doubt that this one is a must-have skill to apply for the job of SDE or software engineer role in Amazon or any other big tech company. Hiring procedures in these companies are kind of similar",
    cover: "../images/blog/b1.jpg",
  },
  {
    id: 2,
    type: "Ankitha",
    date: "Oct. 22, 2022",
    com: "76 COMMENTS ",
    title: "My Experience Microsoft Engage",
    desc: "Microsoft Engage is a national program organized by Microsoft for the present university students to work on live projects with assistance from mentors, who are industry experts and engineers at Microsoft.",
    cover: "../images/blog/b2.jpg",
  },
  {
    id: 3,
    type: "Rupendra",
    date: "Nov. 1, 2022",
    com: "49 COMMENTS ",
    title: "Data Structures in Google",
    desc: "The data structures in Google generally used for this is a hash table, hashmap or inverted index. The inverted index data structure is efficient in keyword-based queries and makes information retrieval convenient.",
    cover: "../images/blog/b3.jpg",
  },
  {
    id: 4,
    type: "Prashant",
    date: "Oct. 01, 2022",
    com: "97 COMMENTS ",
    title: "How to pass Technical Interview",
    desc: "It’s no secret that the technical interview process leaves something to be desired; it’s often riddled with anxiety and stress. In this article, we’ll take a look at seven of the most common mistakes candidates make in their technical interviews and explore ways you can avoid them.",
    cover: "../images/blog/b4.jpg",
  },
  {
    id: 5,
    type: "Ritvik",
    date: "Sep. 21, 2022",
    com: "63 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "Typically, interview in SAP Labs usually has 4 rounds. Two technical rounds. One from the same team as the recruiter is and the second from a different team (usually referred to as non-LOB round) or a Project.",
    cover: "../images/blog/b5.jpg",
  },
  {
    id: 6,
    type: "Abhishek",
    date: "Oct. 18, 2022",
    com: "35 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "There were 2 rounds in my interview. The process started with HR round and ended with Behavioral as the second and final round. I applied via Walk-in and was interviewed before Sep 2021.",
    cover: "../images/blog/b6.jpg",
  },
]

export const testimonal = [
  {
    id: 1,
    name: "Rohith Boppey",
    post: "Student at IIITS",
    desc: "This was my first course at MasterDsa. I attended the Python course in 2021 and loved it. Abdul Bari has good knowledge about the field and were very helpful in making us understand the concepts. I would certainly recommend this to anyone...",
    cover: "./images/testo/r1.jpg",
  },
  {
    id: 2,
    name: "B Karthikeya",
    post: "SDE at Amazon-Luxemburg",
    desc: "MasterDsa was an amazing experience for me. I belong to Ece department and had a little experience in coding but I think it has helped me a lot for coding in analytical manner. Coding is important no matter which branch you are in. It gives you a better understanding about how to approach a problem...",
    cover: "./images/testo/r2.jpg",
  },
  {
    id: 3,
    name: "Ankitha Shah",
    post: "SDE at Microsoft",
    desc: "When I was watching 'Dear Zindagi', I could relate Sharukh Khan to Abdul Bari Sir. The way Sharukh Khan was giving life lessons to Alia Bhatt, in the same way Abdul Bari Sir will give coding life lessons which will guide you throughout your code life...",
    cover: "./images/testo/r3.jpg",
  },
  {
    id: 4,
    name: "Krishna Dey",
    post: "SDE at Amazon",
    desc: "I did DSA course With C with Abdul Bari Sir. It was a great experience. The brain teasers and assignments, actually the whole lot of content was really good. Some problems were challenging yet interesting. Was explained very well where ever I stuck...",
    cover: "./images/testo/r4.jpg",
  },
  {
    id: 5,
    name: "Abhishek Rayudu",
    post: "SDE Intern at Motorla",
    desc: "MasterDsa was an amazing experience for me. I belong to Ece department and had a little experience in coding but I think it has helped me a lot for coding in analytical manner. Coding is important. It gives you a better understanding about how to approach a problem...",
    cover: "./images/testo/r5.jpeg",
  },
  {
    id: 6,
    name: "Ameet Prasith",
    post: "Phd Stud at IITB",
    desc: "This was my first complete course at MasterDsa. I attended the Python course in 2021. Abdul Bari has good knowledge about the field and were  helpful in making us understand the concepts. I would recommend this to anyone...",
    cover: "./images/testo/r6.jpg",
  }
]
