import React from 'react';

// Footer Component
const Footer = () => {
    return (
        <ffooter>
            <p>
                Sorting Algorithm Visualizer <i style={{color: "#FF5252"}}></i>

            </p>
        </ffooter>
    );
}
 
export default Footer;